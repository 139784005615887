<template>
  <div class="resources-container">
    <div class="resources-content clear" v-loading="pageLoading">
      <div class="resources-switch-type">
        <div class="resources-switch-type-title">类型：</div>
        <div class="resources-switch-type-content">
          <div
            class="resources-switch-type-content-li"
            :class="{
              'resources-switch-type-content-li-active': current == index,
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleClickSubCategory(index, item)"
          >
            {{ item.category_name }}
          </div>
        </div>
      </div>
      <div class="resources-switch-type" v-if="subTypeList.length > 0">
        <div class="resources-switch-type-title">类型：</div>
        <div class="resources-switch-type-content">
          <div
            class="resources-switch-type-content-li"
            :class="{
              'resources-switch-type-content-li-active': curr == index,
            }"
            v-for="(item, index) in subTypeList"
            :key="index"
            @click="handleClickCategory(index, item)"
          >
            {{ item.category_name }}111
          </div>
        </div>
      </div>

      <!-- 最新·最热 -->
      <div class="resources-content-hot-new">
        <div class="resources-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="resources-content-hot-new-title"
          @click="handleChangeValue2('sort', 0)"
        >
          <div
            :style="{
              'font-weight': cur == 'sort' ? 'bold' : '',
            }"
          >
            默认
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{
              display: cur == 'sort' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="resources-content-new-mid-hot"></div>
        <div
          class="resources-content-hot-new-title"
          @click="handleChangeValue2('id', 1)"
        >
          <div
            :style="{
              'font-weight': cur == 'id' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{
              display: cur == 'id' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="resources-content-new-mid-hot"></div>
        <div
          class="resources-content-hot-new-title resources-content-hot-new-title1"
          @click="handleChangeValue2('view_count', 2)"
        >
          <div :style="{ 'font-weight': cur == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{ display: cur == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="resources-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div>

      
      <!-- 类型的tab切换中的假内容 -->
      <div
        class="resources-tab-content clear"
        v-if="active_tab == 0 && content.length > 0"
      >
        <listItem
          listType="resources_e"
          :list="content"
          v-loading="listLoading"
        ></listItem>
      </div>

      <!-- 电子资源库的类型为文学小说时的布局 1=视频，2=音频，3=电子书-->

      <div
        class="fiction clear"
        v-loading="listLoading"
        v-if="active_tab == 2 && content.length > 0"
      >
        <listItem
          listType="resources_t"
          :list="content"
          v-loading="listLoading"
        ></listItem>
      </div>
        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="bookLimit"
          @pagination="getResourceList"
        />
      <!-- 没有内容显示的页面 -->
      <Nocontent
        v-if="content.length == 0"
        title="暂无数字资源，请持续关注哦～"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { queryResourceChildCategory, queryResourceList } from "@/api/common";
import { bookListCategory, bookList } from "../../api/resources/resources";
import Pagination from "@/components/Pagination";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";

export default {
  data() {
    return {
      pageLoading: false,
      listLoading: true,
      total: 0,
      page: 1,
      limit: 6,
      bookLimit: 6,
      curr: 0,
      current: 0,
      order: "sort",
      currentType: 0,
      typeList: [],
      subTypeList: [],
      aid: process.env.VUE_APP_AID,
      elcTypeList: [],
      content: [],
      subCategory: "",
      cat_id: "",
      active_tab: 0,
      cur: "sort",
      isSelected: true,
      firstId: "",
      firstCatId: "",
      getChild: 1,
    };
  },
  components: {
    Pagination,
    ListItem,
    Nocontent,
  },
  watch: {
    // isSelected(newV, oldV) {
    //   console.log(newV, oldV);
    //   if (newV) {
    //     this.handleClickSubCategory(0, this.firstCatId);
    //   } else {
    //     this.handleClickChildCategory(0, this.firstId);
    //   }
    // },
    $route() {
      this.cat_id = this.$route.query.cat_id;
      
    },
    cat_id(newV, oldV) {
      console.log(newV, oldV);
      this.cat_id = newV;
      this.getSubCategory(0);
      console.log("调用了函数");
      this.getResourceList();
    },
  },
  mounted() {
    this.getSubCategory(0);
    this.cat_id = this.$route.query.cat_id
      ? this.$route.query.cat_id
      : this.typeList[0].id;
  },
  methods: {
    handleClickCategory(index, item) {
      this.page = 1;
      this.curr = index;
      this.cat_id = item.id;
      this.getResourceList();
    },
    handleClickSubCategory(i, item) {
      console.log(i)
      console.log(item);
      console.log('dddddddddddd')
      this.page = 1;
      this.active_tab = 0;
      this.current = i;
      this.cat_id = item.children_data
        ? item.children_data[0].id
        : item.id;
      this.subTypeList = item.children_data ? item.children_data : [];
      this.curr = 0;
      this.page = 1;
      // this.getResourceList();
    },
    getSubCategory(class_id) {
      queryResourceChildCategory({ aid: this.aid, class_id }).then((res) => {
        this.typeList = res.data;
        var that = this;
        res.data.forEach((item, index) => {
          if (item.id == that.cat_id) {
            that.current = index;
          }
        });
        this.subTypeList = res.data[this.current].children_data;
        this.cat_id = res.data[this.current].children_data[this.curr].id;
        this.getResourceList();
        // this.cat_id = res.data[this.current].children_data[0].id;
        // this.cat_id = res.data[0].id;
        // if (this.typeList.length > 0) {
        //   // this.cat_id = this.typeList[0].id;
        //   this.getResourceList();
        // }
      });
    },
    getSubCategoryElc(class_id) {
      bookListCategory({ aid: this.aid, class_id }).then((res) => {
        console.log(res);
        this.elcTypeList = res.data;
        this.firstId = res.data[0].book_cat_id;
      });
    },
    getResourceList() {
      this.content = [];
      let self = this;
      self.listLoading == true;
      let params = {
        aid: this.aid,
        page: this.page,
        limit: this.limit,
        category_id: this.cat_id,
        sort_field: this.order,
        get_children_list: this.getChild,
      };
      if (this.active_tab == 0) {
        queryResourceList(params).then((res) => {
          if (res.code == 0) {
            this.content = res.data.datalist;
            this.total = res.data.count;
          }
          setTimeout(function() {
            self.listLoading = false;
          }, 1000);
        });
      } else if (this.active_tab == 2) {
        let params = {
          aid: self.aid,
          page: self.page,
          limit: self.bookLimit,
          category_id: self.cat_id,
          sort_field: self.order,
          get_children_list: self.getChild,
        };
        bookList(params).then((res) => {
          console.log(res);
          this.content = res.data.rows;
          this.listLoading = false;
          this.total = res.data.total;
        });
      }
    },
    handleChangeValue2(cur, i) {
      this.cur = cur;
      let order = "";
      switch (i) {
        case 0:
          order = "sort";
          break;
        case 1:
          order = "id";
          break;
        case 2:
          order = "view_count";
          break;
      }
      this.order = order;
      this.getResourceList();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/resources.scss";
</style>
