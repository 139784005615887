var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resources-container"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],staticClass:"resources-content clear"},[_c('div',{staticClass:"resources-switch-type"},[_c('div',{staticClass:"resources-switch-type-title"},[_vm._v("类型：")]),_c('div',{staticClass:"resources-switch-type-content"},_vm._l((_vm.typeList),function(item,index){return _c('div',{key:item.id,staticClass:"resources-switch-type-content-li",class:{
            'resources-switch-type-content-li-active': _vm.current == index,
          },on:{"click":function($event){return _vm.handleClickSubCategory(index, item)}}},[_vm._v(" "+_vm._s(item.category_name)+" ")])}),0)]),(_vm.subTypeList.length > 0)?_c('div',{staticClass:"resources-switch-type"},[_c('div',{staticClass:"resources-switch-type-title"},[_vm._v("类型：")]),_c('div',{staticClass:"resources-switch-type-content"},_vm._l((_vm.subTypeList),function(item,index){return _c('div',{key:index,staticClass:"resources-switch-type-content-li",class:{
            'resources-switch-type-content-li-active': _vm.curr == index,
          },on:{"click":function($event){return _vm.handleClickCategory(index, item)}}},[_vm._v(" "+_vm._s(item.category_name)+"111 ")])}),0)]):_vm._e(),_c('div',{staticClass:"resources-content-hot-new"},[_vm._m(0),_c('div',{staticClass:"resources-content-hot-new-title",on:{"click":function($event){return _vm.handleChangeValue2('sort', 0)}}},[_c('div',{style:({
            'font-weight': _vm.cur == 'sort' ? 'bold' : '',
          })},[_vm._v(" 默认 ")]),_c('div',{staticClass:"resources-content-hot-new-title-bg",style:({
            display: _vm.cur == 'sort' ? 'block' : 'none',
          })})]),_c('div',{staticClass:"resources-content-new-mid-hot"}),_c('div',{staticClass:"resources-content-hot-new-title",on:{"click":function($event){return _vm.handleChangeValue2('id', 1)}}},[_c('div',{style:({
            'font-weight': _vm.cur == 'id' ? 'bold' : '',
          })},[_vm._v(" 最新 ")]),_c('div',{staticClass:"resources-content-hot-new-title-bg",style:({
            display: _vm.cur == 'id' ? 'block' : 'none',
          })})]),_c('div',{staticClass:"resources-content-new-mid-hot"}),_c('div',{staticClass:"resources-content-hot-new-title resources-content-hot-new-title1",on:{"click":function($event){return _vm.handleChangeValue2('view_count', 2)}}},[_c('div',{style:({ 'font-weight': _vm.cur == 'view_count' ? 'bold' : '' })},[_vm._v(" 最热 ")]),_c('div',{staticClass:"resources-content-hot-new-title-bg",style:({ display: _vm.cur == 'view_count' ? 'block' : 'none' })})]),_vm._m(1)]),(_vm.active_tab == 0 && _vm.content.length > 0)?_c('div',{staticClass:"resources-tab-content clear"},[_c('listItem',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"listType":"resources_e","list":_vm.content}})],1):_vm._e(),(_vm.active_tab == 2 && _vm.content.length > 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],staticClass:"fiction clear"},[_c('listItem',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"listType":"resources_t","list":_vm.content}})],1):_vm._e(),_c('pagination',{attrs:{"total":_vm.total,"page":_vm.page,"limit":_vm.bookLimit},on:{"update:page":function($event){_vm.page=$event},"update:limit":function($event){_vm.bookLimit=$event},"pagination":_vm.getResourceList}}),(_vm.content.length == 0)?_c('Nocontent',{attrs:{"title":"暂无数字资源，请持续关注哦～"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resources-content-hot-new-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/title_left_icon.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resources-content-hot-new-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/title_right_icon.png","alt":""}})])
}]

export { render, staticRenderFns }